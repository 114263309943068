import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import AccountView from '@/views/AccountView.vue'
import RouteView from '@/views/RouteView.vue'
import NotificationsView from '@/views/NotificationsView.vue'
import ScannerView from '@/views/ScannerView.vue'
import MyRoutesView from '@/views/MyRoutesView.vue'
import SelectCompanyView from '@/views/SelectCompanyView.vue'
import SearchView from '@/views/SearchView.vue'
import TasksView from '@/views/TasksView.vue'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    linkActiveClass: 'active',
    routes: [
        {
            path: '/login',
            name: 'loginPage',
            component: LoginView
        },
        {
            path: '/company-select',
            name: 'companySelectPage',
            component: SelectCompanyView
        },
        {
            path: '/',
            name: 'homePage',
            component: HomeView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/search',
            name: 'searchPage',
            component: SearchView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tasks',
            name: 'tasksPage',
            component: TasksView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/my-routes',
            name: 'myRoutesPage',
            component: MyRoutesView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/account',
            name: 'accountPage',
            component: AccountView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/route',
            name: 'routePage',
            component: RouteView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/notifications',
            name: 'notificationsPage',
            component: NotificationsView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/scanner',
            name: 'scannerPage',
            component: ScannerView,
            meta: {
                requiresAuth: true
            }
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore()

    if (to.meta.requiresAuth && !authStore.isUserLoggedIn()) {
        next({ name: 'loginPage' })
    } else {
        next()
    }
})

export default router
