<template>
    <div class="client-search-component">
        <div class="left-col">
            <Multiselect
                v-show="searchMode"
                ref="clientSelect"
                placeholder="+380"
                value-prop="phone"
                class="client-select"
                regex="^[\d\s\-\+\(\)]+$"
                inputmode="number"
                :delay="500"
                :searchable="true"
                :filter-results="false"
                :resolve-on-load="true"
                :options="clientsList"
                :close-on-select="true"
                @select="(item, option) => clientSelect(option)"
                @clear="clientClear"
            >
                <template #caret>
                    <CollapseToggler :opened="false" />
                </template>

                <template #singlelabel="{ value }">
                    <div class="value-text">
                        <span class="text-h3">{{ value.phone }}</span>
                    </div>
                </template>
                <template #option="{ option }">
                    <div class="option-text client-option-text">
                        <span v-if="option.surname?.length && option.name?.length" class="text-h4">
                            {{ option.surname + ' ' + option.name }}
                        </span>
                        <span class="text-h4 text-bold">{{ option.phone }}</span>
                    </div>
                </template>

                <template #nooptions>
                    <div class="no-options-drop text-h5">Введіть для пошуку</div>
                </template>
            </Multiselect>
            <PhoneInputComponent v-show="!searchMode" v-model="phoneValue" />
        </div>
        <img :src="buttonIcon" alt="" @click="switchMode" />
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import CollapseToggler from '@/components/CollapseToggler.vue'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import searchIcon from '@/assets/images/search-btn.svg'
import keyboardIcon from '@/assets/images/keyboard-btn.svg'
import PhoneInputComponent from '@/components/form/PhoneInputComponent.vue'

export default {
    name: 'ClientSearchComponent',
    components: { PhoneInputComponent, CollapseToggler, Multiselect },
    props: {
        withAddresses: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: String,
            required: true
        }
    },
    emits: ['clientSelect', 'clientClear', 'update:modelValue'],
    data() {
        return {
            searchMode: false
        }
    },
    computed: {
        phoneValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },

        buttonIcon() {
            return this.searchMode ? keyboardIcon : searchIcon
        }
    },
    watch: {
        searchMode: {
            handler(newVal) {
                if (newVal && this.phoneValue.length) {
                    this.$nextTick(() => {
                        this.focusAndSearch(this.phoneValue)
                    })
                }
            }
        }
    },
    methods: {
        focusAndSearch(query) {
            this.$refs.clientSelect.focus()
            const searchInput = this.$refs.clientSelect.$el.querySelector('input')

            if (searchInput) {
                searchInput.value = query
                const event = new Event('input', {
                    bubbles: true,
                    cancelable: true
                })

                searchInput.dispatchEvent(event)
            }
        },

        switchMode() {
            this.searchMode = !this.searchMode
        },

        async clientsList(query) {
            if (query?.length > 0) {
                const options = await fetchWrapper.post('/clients/search', {
                    phone: query,
                    page: 1,
                    with_addresses: this.withAddresses
                })

                if (!options.length) {
                    this.$toast.warning('Клієнта не знайдено')
                    this.$emit('update:modelValue', query.startsWith('+') ? query : '+' + query)
                    this.searchMode = false

                    return
                }

                return options.map((item) => ({
                    name: item.name,
                    surname: item.surname,
                    phone: item.phone,
                    addresses: this.withAddresses ? item.addresses : []
                }))
            }
        },

        clientSelect(option) {
            this.$emit('clientSelect', option)
            this.$refs.clientSelect.blur()
        },

        clientClear() {
            this.$emit('clientClear')
            this.$refs.clientSelect.blur()
        }
    }
}
</script>

<style scoped lang="scss">
.client-search-component {
    display: flex;
}

.left-col {
    width: 100%;
    margin-right: 0.75rem;
}

.value-text,
.option-text {
    display: flex;
    align-items: center;
}

.client-option-text {
    flex-direction: column;
    align-items: flex-start;
}

.no-options-drop {
    padding: 0.5rem 1rem;
}
</style>
