import { defineStore } from 'pinia'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import { createToaster } from '@meforma/vue-toaster'
import router from '@/router/index.js'

const toaster = createToaster({ position: 'top' })

export const useActiveRouteStore = defineStore({
    id: 'activeRoute',
    state: () => ({
        routeId: null,
        routeData: {},
        updateMap: false
    }),
    actions: {
        loadActiveRouteData() {
            if (router.currentRoute.value.name === 'homePage') {
                router.go(0)
            }

            if (router.currentRoute.value.name === 'routePage' && !this.routeId) {
                router.go(0)
            }

            if (this.routeId) {
                fetchWrapper.post(`/routes/load-page/${this.routeId}`, {}).then((response) => {
                    if (response.status?.value === 'Finished route') {
                        this.routeId = null
                        this.routeData = {}
                    } else {
                        this.routeData = response
                    }
                })
            }
        },

        async getActiveRouteId() {
            await fetchWrapper.post('/main-page/load-info', {}).then((response) => {
                if (response?.nearest_route?.status?.value === 'Active route') {
                    this.routeId = response.nearest_route.id
                } else {
                    this.routeId = null
                    this.routeData = {}
                }
            })
        },

        async startRoute(routeId) {
            this.routeId = null
            this.routeData = {}

            await fetchWrapper.patch(`/routes/update-status/${routeId}`, {
                status: 'Active route'
            })
        },

        async updatePointStatus(point_id, status) {
            fetchWrapper
                .patch(`/route-points/update-status/${point_id}`, {
                    status
                })
                .then((response) => {
                    this.routeData.points.map((item) => {
                        if (item.id === response.route_point_id) {
                            item.status = response.data
                        }
                    })

                    toaster.success('Статус точки оновлено')
                })
        },

        async updateTaskStatus(status, action_id) {
            return fetchWrapper.patch(`/route-point-tasks/update-status/${action_id}`, {
                status
            })
        },

        updateStatusesInRouteData(actionId, response, extra_action = null) {
            if (!this.routeData.points?.length) {
                return
            }

            this.routeData.points.forEach((point) => {
                if (point.id === response.route_point_status.id) {
                    point.status = response.route_point_status.status
                }

                const foundItem = point.items.find((item) => item.action_id === actionId)

                if (foundItem) {
                    foundItem.action_status = response.data

                    if (extra_action) {
                        foundItem.action_type = extra_action
                    }
                }
            })
        },

        updateActiveRoute(newRouteData) {
            this.routeData = newRouteData
        },

        loadPointData(pointId) {
            if (this.routeId) {
                fetchWrapper.post(`/route-points/load-page/${pointId}`, {}).then((response) => {
                    const pointIndex = this.routeData.points.findIndex(
                        (point) => point.id === pointId
                    )

                    this.routeData.points[pointIndex] = response
                })
            }
        }
    }
})
