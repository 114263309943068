<template>
    <div v-if="!showLoader" class="tasks-page">
        <div class="container">
            <div class="page-header" @click="previousPage">
                <img src="@/assets/images/return-icon.svg" class="return-icon" alt="" />
                <span class="text-h5">Повернутись назад</span>
            </div>

            <div class="page-body">
                <RouteTasksComponent
                    v-for="(routeInfo, index) in tasksList"
                    :key="index"
                    :route-info="routeInfo"
                    @task-status-change="updateTaskStatus"
                />
            </div>
        </div>
    </div>
</template>

<script>
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import RouteTasksComponent from '@/components/RouteTasksComponent.vue'

export default {
    name: 'TasksView',
    components: { RouteTasksComponent },
    data() {
        return {
            tasksList: [],
            showLoader: true
        }
    },
    mounted() {
        this.loadModalInfo()
    },
    methods: {
        loadModalInfo() {
            this.showLoader = true
            const entityType = this.$route.query.entityType
            const entityId = this.$route.query.entityId

            fetchWrapper
                .post(`/route-point-entities/load-tasks/${entityId}`, {
                    type: entityType
                })
                .then((response) => {
                    this.tasksList = response
                })
                .finally(() => {
                    this.showLoader = false
                })
        },

        updateTaskStatus(statusData) {
            this.tasksList.forEach((item) => {
                item.tasks.forEach((task) => {
                    if (task.action_id === statusData.actionId) {
                        task.action_status = statusData.status
                    }
                })
            })
        },

        previousPage() {
            this.$router.back()
        }
    }
}
</script>

<style scoped lang="scss">
.tasks-page {
    overflow-y: auto;
    height: 100%;
}

.page-header {
    padding-top: 1.25rem;
    margin-bottom: 1.25rem;
    display: flex;
    align-items: center;

    .return-icon {
        width: 1.875rem;
        height: 1.875rem;
        margin-right: 0.25rem;
    }
}
</style>
