<template>
    <div class="route-tasks-component shadow-sm" :class="{ active: routeInfo.route.active }">
        <div class="route-info">
            <div class="route">
                <span class="route-name text-bold text-h2">{{ routeInfo.route.name }}</span>
                <span class="route-date text-h5 text-grey">
                    {{ routeInfo.route.departure_date }}
                </span>
            </div>
            <div class="route-driver">
                <span class="title text-h5 text-grey">Водії:</span>
                <div>
                    <span class="value text-h4">
                        {{ driversList }}
                    </span>
                </div>
            </div>
            <div class="route-transport">
                <span class="title text-h5 text-grey">Бус:</span>
                <span class="value text-h4">
                    {{ routeInfo.route.transport.title }}
                </span>
            </div>
        </div>
        <div class="tasks-list">
            <div v-for="task in routeInfo.tasks" :key="task.action_id" class="task-item">
                <div class="task-info">
                    <img :src="actionsOptions[task.action_type]?.icon" class="action-icon" alt="" />
                    <span class="text-h4">
                        {{ task.action_route_point?.address }}
                    </span>
                </div>
                <StatusComponent
                    :status-options="taskStatusOptions"
                    :current-status="task.action_status"
                    :disabled="!routeInfo.route.active"
                    @update-status="(status) => updateTaskStatus(status, task.action_id)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import actionMixin from '@/mixins/actionMixin.js'
import statusesMixin from '@/mixins/statusesMixin.js'
import StatusComponent from '@/components/StatusComponent.vue'
import { useActiveRouteStore } from '@/stores/index.js'

export default {
    name: 'RouteTasksComponent',
    components: { StatusComponent },
    mixins: [actionMixin, statusesMixin],
    props: {
        routeInfo: {
            type: Object,
            required: true
        }
    },
    emits: ['task-status-change'],
    setup() {
        const activeRouteStore = useActiveRouteStore()

        return { activeRouteStore }
    },
    computed: {
        driversList() {
            return this.routeInfo.route.drivers.map((driver) => driver.full_name).join(', ')
        }
    },
    methods: {
        updateTaskStatus(status, actionId) {
            this.activeRouteStore.updateTaskStatus(status, actionId).then((response) => {
                this.activeRouteStore.updateStatusesInRouteData(actionId, response)
                this.$emit('task-status-change', { status: response.data, actionId })
                this.$toast.success('Статус завдання змінено')
            })
        }
    }
}
</script>

<style scoped lang="scss">
.route-tasks-component {
    padding: 0.875rem 1.25rem 1.25rem;
    border-radius: 0.625rem;
    margin-bottom: 1.25rem;

    &.active {
        border: 1px solid $primary;
    }

    .route-info {
        padding-bottom: 0.75rem;
        border-bottom: 1px solid $grey-light;
    }

    .route {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.625rem;

        &-name {
            color: $primary;
            margin-right: 1.25rem;
        }
    }

    .route-transport,
    .route-driver {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        .title {
            margin-right: 0.5rem;
        }
    }

    .task-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.25rem 0;
        border-bottom: 1px solid $grey-light;

        &:last-of-type {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    .task-info {
        display: flex;
        align-items: center;
        margin-right: 1.25rem;

        .action-icon {
            margin-right: 0.5rem;
        }
    }
}
</style>
