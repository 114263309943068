import { parsePhoneNumber } from 'libphonenumber-js'

export const parsePhone = (phoneNum) => {
    if (phoneNum.length > 1) {
        let formattedPhone = parsePhoneNumber(phoneNum)

        formattedPhone.formatInternational()

        return formattedPhone.isValid()
    }

    return false
}
