<template>
    <MainLayout>
        <div class="active-route-page">
            <div class="container">
                <template v-if="activeRouteStore.routeId">
                    <div class="active-route-header shadow-sm">
                        <h1 class="route-name text-h3 text-bold">
                            {{ activeRouteStore.routeData.name }}
                        </h1>
                        <div class="points-count text-h4 text-grey">
                            К-сть зупинок: {{ routePointsCount }}
                        </div>
                    </div>
                    <div class="active-route-map">
                        <MapComponent
                            v-if="activeRouteStore.routeData.points?.length"
                            :waypoints="activeRouteStore.routeData.points"
                        />
                    </div>
                    <div class="active-route-actions">
                        <div class="add-item-btn shadow-sm" @click.stop="showAddOverlay">
                            <img src="@/assets/images/add-item-icon.svg" alt="" />
                        </div>
                        <div
                            v-if="activeRouteStore.routeData.points?.length"
                            class="open-in-maps-btn shadow-sm"
                            @click.stop="openInNavigator"
                        >
                            <img src="@/assets/images/map-icon.svg" />
                        </div>
                        <div class="finish-route-btn shadow-sm" @click.stop="finishRoute">
                            <img src="@/assets/images/stop-route-icon.svg" alt="" />
                        </div>
                    </div>
                    <div class="active-route-body shadow-sm">
                        <ActiveRoutePoint
                            v-for="point in activeRouteStore.routeData.points"
                            :key="point.id"
                            :point-data="point"
                            :last-completed="lastCompletedPoint === point.id"
                            :first-uncompleted="firstUncompletedPoint === point.id"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="missing-active-route">
                        <span class="text-h4 text-grey text-center">
                            У вас немає активного маршруту
                        </span>
                    </div>
                </template>
            </div>

            <transition name="slide-in">
                <div
                    v-show="showOverlay"
                    class="add-item-overlay"
                    @click.stop="showOverlay = false"
                >
                    <div class="overlay-actions">
                        <div class="hide-overlay-btn shadow-sm" @click.stop="showOverlay = false">
                            <img src="@/assets/images/close-overlay-icon.svg" alt="" />
                        </div>
                        <div class="add-parcel-btn" @click.stop="showAddParcelModal">
                            <span> Додати посилку </span>
                            <img src="@/assets/images/add-parcel-icon.svg" alt="" />
                        </div>
                        <div class="add-passenger-btn" @click.stop="showAddPassengerModal">
                            <span> Додати пасажира </span>
                            <img src="@/assets/images/add-passenger-icon.svg" alt="" />
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <EntityModal />
        <AddParcelModal @hide-overlay="showOverlay = false" />
        <AddPassengerModal @hide-overlay="showOverlay = false" />
        <Transition name="fade">
            <LoaderComponent v-if="showLoader" />
        </Transition>
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue'
import ActiveRoutePoint from '@/components/activeRoute/ActiveRoutePoint.vue'
import MapComponent from '@/components/MapComponent.vue'
import { useActiveRouteStore, useModalStore } from '@/stores/index.js'
import EntityModal from '@/components/modals/EntityModal.vue'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import AddParcelModal from '@/components/modals/AddParcelModal.vue'
import AddPassengerModal from '@/components/modals/AddPassengerModal.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import { Geolocation } from '@capacitor/geolocation'

export default {
    name: 'RouteView',
    components: {
        AddPassengerModal,
        AddParcelModal,
        EntityModal,
        MapComponent,
        ActiveRoutePoint,
        MainLayout,
        LoaderComponent
    },
    setup() {
        const activeRouteStore = useActiveRouteStore()
        const modalStore = useModalStore()

        return { activeRouteStore, modalStore }
    },
    data() {
        return {
            routeData: {},
            showOverlay: false,
            showLoader: true
        }
    },
    computed: {
        routePointsCount() {
            return this.activeRouteStore.routeData.points?.[
                this.activeRouteStore.routeData.points?.length - 1
            ].sort
        },

        lastCompletedPoint() {
            for (let i = this.activeRouteStore.routeData.points?.length - 1; i >= 0; i--) {
                if (this.activeRouteStore.routeData.points[i].status.value === 'Completed') {
                    return this.activeRouteStore.routeData.points[i].id
                }
            }

            return null
        },

        firstUncompletedPoint() {
            return this.activeRouteStore.routeData.points?.find(
                (obj) => obj.status.value === 'Uncompleted'
            )?.id
        }
    },
    mounted() {
        this.activeRouteStore
            .getActiveRouteId()
            .then(() => {
                if (this.activeRouteStore.routeId) {
                    this.activeRouteStore.loadActiveRouteData()
                }
            })
            .finally(() => {
                this.showLoader = false
            })
    },
    methods: {
        async openInNavigator() {
            this.showLoader = true

            if (
                !this.activeRouteStore.routeData.points ||
                this.activeRouteStore.routeData.points.length === 0
            ) {
                this.showLoader = false
                this.$toast.error('Немає точок для прокладання маршруту')

                return
            }

            try {
                const hasPermission = await Geolocation.checkPermissions()

                if (hasPermission.location === 'denied') {
                    this.showLoader = false
                    this.$toast.error('Надайте додатку доступ до точної геолокації')

                    return
                }
            } catch (e) {
                this.showLoader = false
                this.$toast.error('Увімкніть геолокацію')

                return
            }

            const location = await Geolocation.getCurrentPosition({ enableHighAccuracy: true })
            const currentLocation = [location?.coords?.latitude, location?.coords?.longitude]

            let coordinates = this.activeRouteStore.routeData.points
                .filter((waypoint) => waypoint.status.value === 'Uncompleted')
                .map((waypoint) => waypoint.coordinates.join(','))
                .slice(0, 9)

            if (currentLocation[0]?.length) {
                coordinates.unshift(currentLocation)
            } else {
                coordinates.unshift('My+location')
            }

            let url = 'https://www.google.com/maps/dir/' + coordinates.join('/')

            this.showLoader = false
            window.open(url, '_blank').focus()
        },

        showAddOverlay() {
            this.showOverlay = true
        },

        showAddParcelModal() {
            this.modalStore.addParcelModal.show = true
        },

        showAddPassengerModal() {
            this.modalStore.addPassengerModal.show = true
        },

        finishRoute() {
            fetchWrapper
                .patch(`/routes/update-status/${this.activeRouteStore.routeId}`, {
                    status: 'Finished route'
                })
                .then(() => {
                    this.$toast.success('Маршрут завершено')
                    this.activeRouteStore.routeId = null
                    this.activeRouteStore.routeData = {}
                    this.$router.push({ name: 'homePage' })
                })
        }
    }
}
</script>

<style scoped lang="scss">
.main-layout {
    padding-bottom: 4.5rem;
}

.active-route-page {
    position: relative;
    padding: 1.25rem 0;
    height: 100%;

    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.active-route-header {
    position: relative;
    padding: 1rem 1.25rem;
    background: $white;
    border-radius: 0.8rem;
    overflow: hidden;
    z-index: 3;

    .route-name {
        max-height: 3.63rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
}

.active-route-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    z-index: 1;
}

.active-route-actions {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 9rem;
    right: 1rem;

    .open-in-maps-btn {
        display: flex;
        z-index: 1;
        border-radius: 50%;
        margin-bottom: 0.7rem;
        cursor: pointer;
    }

    .add-item-btn {
        display: flex;
        z-index: 1;
        border-radius: 50%;
        margin-bottom: 0.7rem;
        cursor: pointer;
    }

    .finish-route-btn {
        display: flex;
        z-index: 1;
        border-radius: 50%;
        cursor: pointer;
    }
}

.active-route-body {
    position: relative;
    padding: 1rem 1.25rem 0 1.25rem;
    background: $white;
    border-radius: 0.8rem;
    overflow: auto;
    max-height: 45vh;
    z-index: 3;

    .active-route-point {
        border-bottom: 1px solid $grey-light;

        &:last-of-type {
            border-bottom: none;
        }
    }
}

.missing-active-route {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.add-item-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(2px);
    z-index: 505;

    .overlay-actions {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        top: 9rem;
        right: 1rem;
    }

    .hide-overlay-btn {
        display: flex;
        z-index: 1;
        border-radius: 50%;
        margin-bottom: 0.7rem;
        cursor: pointer;
    }

    .add-parcel-btn,
    .add-passenger-btn {
        display: flex;
        align-items: center;
        margin-bottom: 0.7rem;
        cursor: pointer;

        span {
            margin-right: 0.7rem;
        }
    }
}

.slide-in-enter-active {
    animation: slide-in 0.3s;
}

.slide-in-leave-active {
    animation: slide-in 0.2s reverse;
}

@keyframes slide-in {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
</style>
