<template>
    <div class="status-component">
        <div
            class="current-status text-h4"
            :class="{ disabled: disabled }"
            :style="{ background: currentStatusObject.color }"
            @click="openDropdown"
        >
            {{ currentStatusObject.name }}
        </div>
        <div
            v-if="dropdownVisible"
            v-click-outside="onClickOutside"
            class="status-dropdown shadow-sm"
        >
            <div
                v-for="(status, index) in statusOptions"
                :key="index"
                class="status-option"
                @click="updateStatus(status.value)"
            >
                {{ status.name }}
            </div>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
export default {
    name: 'StatusComponent',
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        currentStatus: {
            type: Object,
            required: true
        },
        statusOptions: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update-status'],
    data() {
        return {
            dropdownVisible: false
        }
    },
    computed: {
        currentStatusObject() {
            return this.statusOptions.filter((item) => {
                return item.value === this.currentStatus.value
            })[0]
        }
    },
    methods: {
        openDropdown() {
            if (this.disabled) {
                return
            }
            this.dropdownVisible = true
        },

        updateStatus(status) {
            this.dropdownVisible = false
            this.$emit('update-status', status)
        },

        onClickOutside() {
            if (this.dropdownVisible === true) {
                this.dropdownVisible = false
            }
        }
    }
}
</script>

<style scoped lang="scss">
.status-component {
    position: relative;
    width: fit-content;

    .current-status {
        width: fit-content;
        color: $white;
        border-radius: 0.7rem;
        padding: 0.25rem 0.5rem;
        white-space: nowrap;

        &:not(.disabled) {
            cursor: pointer;
        }
    }

    .status-dropdown {
        position: absolute;
        right: 0;
        border-radius: 0.7rem;
        background: $white;
        width: fit-content;
        z-index: 5;
    }

    .status-option {
        padding: 0.7rem 1rem;
        white-space: nowrap;
        border-bottom: 1px solid $grey-light;

        &:last-of-type {
            border-bottom: 0;
        }
    }
}
</style>
