<template>
    <MainLayout>
        <div class="search-page">
            <div class="container">
                <div class="page-header">
                    <router-link :to="{ name: 'homePage' }" class="return-link">
                        <img src="@/assets/images/return-icon.svg" class="return-icon" alt="" />
                    </router-link>
                    <div class="text-h2 text-bold">Пошук:</div>
                </div>
                <div class="search-field">
                    <img src="@/assets/images/search-icon-grey.svg" class="search-icon" alt="" />
                    <input
                        v-model="searchQuery"
                        type="text"
                        placeholder="+380"
                        class="search-input text-h4 shadow-sm"
                        @input="debouncedSearch"
                    />
                </div>
                <div class="search-tabs">
                    <div
                        class="tab-btn text-h4"
                        :class="{ active: currentSearchType === 'parcel' }"
                        @click="switchSearchTab('parcel')"
                    >
                        Посилки
                    </div>
                    <div
                        class="tab-btn text-h4"
                        :class="{ active: currentSearchType === 'passenger' }"
                        @click="switchSearchTab('passenger')"
                    >
                        Пасажири
                    </div>
                </div>

                <div class="results-list">
                    <SearchResultComponent
                        v-for="(item, index) in searchResultsList"
                        :key="index"
                        :search-result-info="item"
                        :result-type="currentSearchType"
                    />
                    <div
                        v-if="!lastPage && searchResultsList.length && !showLoader"
                        class="load-more-btn text-h4 text-grey text-center"
                        @click="loadSearchResultsList(false)"
                    >
                        Завантажити ще
                    </div>
                    <div v-if="lastPage && !showLoader" class="text-h4 text-grey text-center">
                        Всі
                        {{ currentSearchType === 'parcel' ? 'посилки' : 'пасажири' }} завантажені
                    </div>
                    <Transition name="fade">
                        <LoaderComponent v-if="showLoader" />
                    </Transition>
                </div>
            </div>
        </div>
        <EntityModal />
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import LoaderComponent from '@/components/LoaderComponent.vue'
import SearchResultComponent from '@/components/SearchResultComponent.vue'
import { debounce } from 'lodash'
import EntityModal from '@/components/modals/EntityModal.vue'

export default {
    name: 'SearchView',
    components: { EntityModal, SearchResultComponent, LoaderComponent, MainLayout },
    data() {
        return {
            debouncedSearch: null,
            searchQuery: '',
            searchResultsList: [],
            currentSearchType: 'parcel',
            pageNum: 1,
            lastPage: false,
            showLoader: false
        }
    },
    created() {
        this.debouncedSearch = debounce(this.loadSearchResultsList, 500)
    },
    methods: {
        switchSearchTab(status) {
            this.searchResultsList = []
            this.currentSearchType = status
            this.pageNum = 1
            this.lastPage = false
            this.loadSearchResultsList()
        },

        loadSearchResultsList(firstPage = true) {
            if (this.searchQuery.length > 1) {
                this.showLoader = true

                const requestPage = firstPage ? 1 : this.pageNum + 1

                fetchWrapper
                    .post('/route-point-entities/search', {
                        search: this.searchQuery,
                        type: this.currentSearchType,
                        page: requestPage
                    })
                    .then((response) => {
                        if (firstPage) {
                            this.pageNum = 1
                            this.searchResultsList = response
                        } else {
                            this.pageNum++
                            this.searchResultsList.push(...response)
                        }

                        if (response.length < 10) {
                            this.lastPage = true
                        } else {
                            this.lastPage = false
                        }
                    })
                    .finally(() => {
                        this.showLoader = false
                    })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.main-layout {
    padding-bottom: 4.5rem;
}

.search-page {
    padding: 1.25rem 0 0;
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.page-header,
.search-field,
.search-tabs {
    flex: 0 1 auto;
}

.results-list {
    flex: 1 1 auto;
    padding-bottom: 5.75rem;
}

.page-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .return-link {
        display: flex;
        margin-right: 1rem;
    }

    .return-icon {
        width: 1.875rem;
        height: 1.875rem;
    }
}

.search-field {
    position: relative;
    margin-bottom: 1.25rem;

    .search-input {
        width: 100%;
        padding: 0.75rem 1rem 0.75rem 2.5rem;
        background: $white;
        border: none;
        border-radius: 0.625rem;
    }

    .search-icon {
        position: absolute;
        top: 50%;
        left: 0.625rem;
        transform: translateY(-50%);
    }
}

.search-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    border-bottom: 2px solid $grey-light;

    .tab-btn {
        position: relative;
        text-align: center;
        width: calc(50% - 0.5rem);
        padding-bottom: 1rem;

        &.active {
            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 2px;
                background: $yellow;
            }
        }
    }
}

.results-list {
    position: relative;
}
</style>
