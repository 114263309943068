import startTripIcon from '@/assets/images/start_trip.svg'
import finishTripIcon from '@/assets/images/finish_trip.svg'
import startTransferIcon from '@/assets/images/start_transfer.svg'
import finishTransferIcon from '@/assets/images/finish_transfer.svg'
import returnActionIcon from '@/assets/images/return_action.svg'
import retryActionIcon from '@/assets/images/retry_action.svg'

export default {
    data() {
        return {
            actionsOptions: {
                start_trip: {
                    key: 'start_trip',
                    label: 'Посадка',
                    icon: startTripIcon
                },
                finish_trip: {
                    key: 'finish_trip',
                    label: 'Висадка',
                    icon: finishTripIcon
                },
                start_transfer: {
                    key: 'start_transfer',
                    label: 'Пересадка (П)',
                    icon: startTransferIcon
                },
                finish_transfer: {
                    key: 'finish_transfer',
                    label: 'Пересадка (В)',
                    icon: finishTransferIcon
                },
                return_action: {
                    key: 'return_action',
                    label: 'Не забрана посилка',
                    icon: returnActionIcon
                },
                retry_action: {
                    key: 'retry_action',
                    label: 'Посилка завтрашнього дня',
                    icon: retryActionIcon
                }
            },

            actionsOptionsArray: [
                {
                    key: 'start_trip',
                    label: 'Посадка',
                    icon: startTripIcon
                },
                {
                    key: 'finish_trip',
                    label: 'Висадка',
                    icon: finishTripIcon
                },
                {
                    key: 'start_transfer',
                    label: 'Пересадка (П)',
                    icon: startTransferIcon
                },
                {
                    key: 'finish_transfer',
                    label: 'Пересадка (В)',
                    icon: finishTransferIcon
                }
            ]
        }
    }
}
