<template>
    <div class="search-result-component shadow-sm" @click.stop.prevent="openEntityModal">
        <div class="card-header">
            <img :src="entityIcon" class="entity-icon" alt="" />
            <div class="header-info">
                <div class="top-row">
                    <span class="text-h5 text-grey">
                        {{ resultType === 'parcel' ? 'Посилка' : 'Пасажир' }} №{{
                            searchResultInfo.id
                        }}
                    </span>
                    <span class="internal-id text-h5 text-grey text-bold">
                        {{ searchResultInfo.internal_id }}
                    </span>
                </div>
                <div class="client-name text-h5 text-bold">
                    {{
                        resultType === 'parcel'
                            ? searchResultInfo.sender.full_name
                            : searchResultInfo.name
                    }}
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="from-location location">
                <div class="from-visual visual">
                    <img src="@/assets/images/from-icon.svg" class="direction-icon" alt="" />
                    <div class="dash-line"></div>
                </div>
                <div class="from-info">
                    <div class="location-name text-h5 text-bold">
                        {{ searchResultInfo.from.address }}
                    </div>
                    <!--                    <div class="location-date text-h5 text-grey">13.07.2023 13:00</div>-->
                </div>
            </div>
            <div class="to-location location">
                <div class="to-visual visual">
                    <img src="@/assets/images/to-icon.svg" class="direction-icon" alt="" />
                </div>
                <div class="from-info">
                    <div class="location-name text-h5 text-bold">
                        {{ searchResultInfo.to.address }}
                    </div>
                    <!--                    <div class="location-date text-h5 text-grey">13.07.2023 13:00</div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useModalStore } from '@/stores/index.js'

export default {
    name: 'SearchResultComponent',
    props: {
        searchResultInfo: {
            type: Object
        },
        resultType: {
            type: String,
            required: true
        }
    },
    setup() {
        const modalStore = useModalStore()

        return { modalStore }
    },
    computed: {
        entityIcon() {
            return this.resultType === 'parcel'
                ? new URL('@/assets/images/parcel-icon.svg', import.meta.url).href
                : new URL('@/assets/images/passenger-icon.svg', import.meta.url).href
        }
    },
    methods: {
        openEntityModal() {
            this.modalStore.entityModal.show = true
            this.modalStore.entityModal.entityType = this.resultType
            this.modalStore.entityModal.entityId = this.searchResultInfo.id
        }
    }
}
</script>

<style scoped lang="scss">
.search-result-component {
    border-radius: 0.8rem;
    padding: 1.25rem;
    margin-bottom: 1.25rem;
}

.card-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $grey-light;
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;

    .entity-icon {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.5rem;
    }

    .header-info {
        width: 100%;
    }

    .top-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.25rem;

        .internal-id {
            margin-left: 1rem;
        }
    }
}

.card-body {
    .location {
        display: flex;
    }

    .visual {
        margin-right: 0.25rem;
    }

    .location-name {
        margin-bottom: 0.25rem;
    }

    .direction-icon {
        min-width: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        margin-bottom: 0.125rem;
    }

    .from-location {
        .location-date {
            padding-bottom: 0.75rem;
        }
    }

    .from-visual {
        display: flex;
        flex-direction: column;
        align-items: center;

        .dash-line {
            height: 100%;
            width: 2px;
            background: url('@/assets/images/dash-line.svg') repeat-y;
            background-size: cover;
        }
    }
}
</style>
