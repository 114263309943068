import { defineStore } from 'pinia'

export const useModalStore = defineStore({
    id: 'modal',
    state: () => ({
        deleteConfirmationModal: {
            show: false,
            itemIdToDelete: null
        },
        entityModal: {
            show: false,
            entityType: null,
            entityId: null
        },
        addParcelModal: {
            show: false
        },
        addPassengerModal: {
            show: false
        }
    }),
    actions: {}
})
