<template>
    <div class="radio-component">
        <label v-for="option in options" :key="option.value" class="radio-label">
            <input
                v-model="selectedValue"
                type="radio"
                :value="option.value"
                class="radio-input"
                @change="handleChange"
            />
            <span class="radio-custom"></span>
            <span class="label-text text-h3">{{ option.label }}</span>
            <img v-if="option.img" class="label-img" :src="option.img" alt="" />
        </label>
    </div>
</template>

<script>
export default {
    name: 'RadioButton',
    props: {
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        modelValue: {
            type: [String, Number, Boolean],
            default: null
        }
    },
    data() {
        return {
            selectedValue: this.modelValue
        }
    },
    watch: {
        modelValue(newValue) {
            this.selectedValue = newValue
        }
    },
    methods: {
        handleChange() {
            this.$emit('update:modelValue', this.selectedValue)
        }
    }
}
</script>

<style lang="scss" scoped>
.radio-component {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.25rem;
}

.radio-label {
    display: flex;
    align-items: center;
    min-width: 50%;
    margin-bottom: 1.25rem;
    cursor: pointer;

    &:nth-child(even) {
        justify-content: flex-end;
    }
}

.radio-input {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
}

.radio-custom {
    position: relative;
    width: 0.875rem;
    height: 0.875rem;
    border: 2px solid $grey-light;
    border-radius: 50%;
    margin-right: 0.75rem;
}

.radio-input:checked + .radio-custom {
    border-color: $yellow;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background: $yellow;
        transform: translate(-50%, -50%);
    }
}

.label-img {
    width: 1.375rem;
    height: 1.375rem;
    margin-left: 0.25rem;
}
</style>
