<template>
    <div class="images-component">
        <div class="block-header">
            <div class="row-title" :class="[titleClass]">{{ blockTitle }}</div>
            <template v-if="isWebPlatform">
                <input
                    :id="`image-input-${randomId}`"
                    type="file"
                    class="file-input"
                    multiple
                    accept="image/*"
                    @change="handleFileChange($event)"
                />

                <label :for="`image-input-${randomId}`">
                    <img
                        class="load-image-btn"
                        src="@/assets/images/load-document-image.svg"
                        alt=""
                    />
                </label>
            </template>

            <template v-else>
                <div @click="uploadImagesMobile">
                    <img
                        class="load-image-btn"
                        src="@/assets/images/load-document-image.svg"
                        alt=""
                    />
                </div>
            </template>
        </div>
        <div v-if="blockData?.length" class="block-body">
            <div v-for="(image, index) in blockData" :key="index" class="body-item shadow-sm">
                <img class="item-image" :src="image.url" alt="" />
                <img
                    class="delete-icon shadow-sm"
                    src="@/assets/images/delete-icon.svg"
                    alt=""
                    @click="deleteImage(image.id)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { Capacitor } from '@capacitor/core'
import { Camera, CameraResultType } from '@capacitor/camera'
import { useModalStore } from '@/stores/index.js'

export default {
    name: 'ImagesComponent',
    props: {
        blockTitle: {
            type: String,
            required: true
        },
        blockData: {
            type: Array,
            required: true
        },
        formDataKey: {
            type: String,
            required: true
        },
        titleClass: {
            type: String,
            required: true
        }
    },
    emits: ['load-image'],
    setup() {
        const modalStore = useModalStore()

        return { modalStore }
    },
    computed: {
        randomId() {
            return Math.random().toString(16).slice(2)
        },

        isWebPlatform() {
            return Capacitor.getPlatform() === 'web'
        }
    },
    methods: {
        async handleFileChange(event) {
            const formData = new FormData()
            const files = [...event.target.files]

            for (let i = 0; i < files.length; i++) {
                formData.append(this.formDataKey, files[i])
            }

            this.saveImages(formData)
        },

        async uploadImagesMobile() {
            const response = await Camera.requestPermissions()

            if (response.camera !== 'granted') {
                this.$toast.error('Надайте доступи додатку до камери')

                return
            }

            if (response.photos !== 'granted') {
                this.$toast.error('Надайте доступи додатку до галереї')

                return
            }

            await Camera.getPhoto({
                resultType: CameraResultType.Uri
            })
                .then(async (file) => {
                    const blob = await fetch(file.webPath).then((res) => res.blob())
                    const imageFile = new File([blob], `image.${file.format}`)
                    const formData = new FormData()

                    formData.append(this.formDataKey, imageFile)

                    this.saveImages(formData)
                })
                .catch(async () => {})
        },

        saveImages(formData) {
            this.$emit('load-image', formData)
        },

        deleteImage(id) {
            this.modalStore.deleteConfirmationModal.show = true
            this.modalStore.deleteConfirmationModal.itemIdForDelete = id
        }
    }
}
</script>

<style scoped lang="scss">
.images-component {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $grey-light;
    padding: 1rem 0;
}

.block-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .row-title {
        margin-right: 1.5rem;
    }

    .file-input {
        display: none;
    }

    .load-image-btn {
        cursor: pointer;
    }
}

.block-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .body-item {
        position: relative;
        width: calc(50% - 0.25rem);
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
        overflow: hidden;
        padding-top: 28%;

        &:nth-child(1),
        &:nth-child(2) {
            margin-top: 1.25rem;
        }

        .item-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .delete-icon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        z-index: 5;
    }
}
</style>
