import { defineStore } from 'pinia'
import fetchWrapper from '@/helpers/fetch-wrapper'
import router from '@/router'
import parseJwt from '@/helpers/parse-jwt'
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'
import { PushNotifications } from '@capacitor/push-notifications'

const baseRoute = '/auth'

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        user: null,
        refreshTokenTimer: null,
        companiesList: []
    }),
    actions: {
        async login(data) {
            try {
                const response = await fetchWrapper.post(`${baseRoute}/login`, {
                    ...data,
                    is_mobile: true
                })

                await this.handleLoginSuccess(response)
            } catch (error) {
                console.error('Login failed:', error)
            }
        },

        async handleLoginSuccess(response, redirect = true) {
            this.setUser(parseJwt(response.access_token))
            await this.storeRefreshToken(response.refresh_token)
            this.startRefreshTokenTimer()

            if (response.companies_for_select?.length) {
                this.companiesList = response.companies_for_select
                await router.push({ name: 'companySelectPage' })
            } else if (redirect) {
                await router.push({ name: 'homePage' })
            }
        },

        async logout() {
            try {
                await fetchWrapper.post(
                    `${baseRoute}/logout`,
                    {},
                    {
                        headers: {
                            Authorization: this.user.access_token
                        }
                    }
                )
            } catch (error) {
                console.error('Logout failed:', error)
            } finally {
                this.clearUserSession()
            }
        },

        async refreshToken() {
            try {
                const refreshToken = await this.getStoredRefreshToken()

                if (!refreshToken) {
                    throw new Error('No refresh token available')
                }

                const response = await fetchWrapper.post(
                    `${import.meta.env.VITE_API_URL + baseRoute}/refresh`,
                    {
                        refresh_token: refreshToken,
                        services: [import.meta.env.VITE_SERVICE_TYPE],
                        is_mobile: true
                    }
                )

                await this.handleLoginSuccess(response, false)
            } catch (error) {
                console.error('Refresh token failed:', error.message ? error.message : error)
                this.clearUserSession(true)
            }
        },

        async storeRefreshToken(token) {
            await SecureStoragePlugin.set({ key: 'refresh_token', value: token })
        },

        async getStoredRefreshToken() {
            const { value } = await SecureStoragePlugin.get({ key: 'refresh_token' })

            return value
        },

        clearUserSession(disablePush = false) {
            this.stopRefreshTokenTimer()
            this.user = {}
            SecureStoragePlugin.remove({ key: 'refresh_token' })

            if (router.currentRoute.value.meta.requiresAuth) {
                router.push({ name: 'loginPage' })
            }

            if (disablePush) {
                PushNotifications.unregister()
            }
        },

        setUser(user) {
            this.user = user
        },

        startRefreshTokenTimer() {
            const expires = new Date(this.user.exp * 1000)
            const timeout = expires.getTime() - Date.now() - 60 * 1000

            this.refreshTokenTimer = setTimeout(() => this.refreshToken(), timeout)
        },

        stopRefreshTokenTimer() {
            clearTimeout(this.refreshTokenTimer)
        },

        isUserLoggedIn() {
            return !!this.user?.access_token
        }
    }
})
