<template>
    <div class="select-company-page">
        <div class="container">
            <form class="company-form shadow-sm" @submit.prevent="submitCompany">
                <h1 class="form-title text-h1 text-bold text-center">Вибір компанії</h1>
                <p class="form-description text-h4 text-center">
                    для того, щоб користуватись сервісом, оберіть Вашу компанію
                </p>

                <div class="select-wrap">
                    <Multiselect
                        ref="companySelect"
                        class="company-"
                        placeholder="Оберіть компанію"
                        value-prop="name"
                        :options="authStore.companiesList"
                        :resolve-on-load="true"
                        :close-on-select="true"
                        @change="handleCompanyChange"
                        @select="handleCompanySelect"
                        @clear="handleCompanyClear"
                    >
                        <template #caret>
                            <CollapseToggler :opened="false" />
                        </template>

                        <template #singlelabel="{ value }">
                            <div class="value-text">
                                <span class="text-h3">{{ value.name }}</span>
                            </div>
                        </template>
                        <template #option="{ option }">
                            <div class="option-text">
                                <span class="text-h4">{{ option.name }}</span>
                            </div>
                        </template>

                        <template #nooptions>
                            <div class="no-options-drop text-h5">
                                Ви не належите до жодної компанії
                            </div>
                        </template>
                    </Multiselect>
                </div>

                <ButtonComponent btn-style="gradient" class="text-h2" text="Увійти" type="submit" />
            </form>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import CollapseToggler from '@/components/CollapseToggler.vue'
import Multiselect from '@vueform/multiselect'
import { useAuthStore } from '@/stores/index.js'
import fetchWrapper from '@/helpers/fetch-wrapper.js'

export default {
    name: 'SelectCompanyView',
    components: { CollapseToggler, ButtonComponent, Multiselect },
    setup() {
        const authStore = useAuthStore()

        return {
            authStore
        }
    },
    data() {
        return {
            companyToken: ''
        }
    },
    mounted() {
        if (!this.authStore.companiesList.length) {
            this.$router.push({ name: 'loginPage' })
        }
    },
    methods: {
        handleCompanyChange() {
            this.$refs.companySelect.blur()
        },

        handleCompanySelect(option, element) {
            this.companyToken = element.token
            this.$refs.companySelect.blur()
        },

        handleCompanyClear() {
            this.companyToken = ''
        },

        submitCompany() {
            if (this.companyToken.length) {
                fetchWrapper
                    .post('/select-company', { company_token: this.companyToken })
                    .then((response) => {
                        this.authStore.handleLoginSuccess(response)
                    })
            } else {
                this.$toast.error('Виберіть компанію')
            }
        }
    }
}
</script>

<style scoped lang="scss">
.select-company-page {
    position: relative;
    height: 100%;
    background-image: url('@/assets/images/company-bg.svg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;

    .container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.company-form {
    width: calc(100% - 2rem);
    max-width: 30rem;
    background: $white;
    padding: 3rem 1rem;
    border-radius: 2rem;

    .form-title {
        margin-bottom: 0.8rem;
    }

    .form-description {
        margin-bottom: 2rem;
    }
}

@media screen and (max-height: 750px) {
    .select-company-page {
        padding: 2rem 0;
    }
}
</style>
