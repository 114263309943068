<template>
    <div ref="activeRoutePoint" class="active-route-point">
        <div class="route-point-header" @click.stop="togglePointCollapse">
            <div class="route-status" :style="{ background: pointData.status.color }"></div>
            <div class="route-address text-h5 text-bold">
                {{ pointData.address }}
            </div>
            <div class="route-collapse">
                <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="collapse-icon"
                    :class="{ opened: !collapsed }"
                >
                    <circle cx="15" cy="15" r="10.5" fill="#EFEFEF" />
                    <path d="M10.5 13.5L15 18" stroke="black" stroke-linecap="round" />
                    <path d="M19.5 13.5L15 18" stroke="black" stroke-linecap="round" />
                </svg>
            </div>
        </div>
        <Collapse :when="!collapsed">
            <div class="route-point-body">
                <template v-if="pointData.items.length">
                    <ActiveRouteTask
                        v-for="(task, key) in pointData.items"
                        :key="key"
                        :task-data="task"
                    />
                </template>
                <template v-else>
                    <div class="no-tasks text-h4 text-grey">У вас немає завдань на цій точці</div>
                </template>
                <ButtonComponent
                    v-if="pointData.status.value !== 'Completed' && firstUncompleted"
                    btn-style="gradient"
                    text="Позначити точку виконаною"
                    @click="completePoint"
                />
                <ButtonComponent
                    v-if="pointData.status.value !== 'Uncompleted' && lastCompleted"
                    btn-style="grey"
                    text="Позначити точку невиконаною"
                    @click="uncompletePoint"
                />
            </div>
        </Collapse>
    </div>
</template>

<script>
import ActiveRouteTask from '@/components/activeRoute/ActiveRouteTask.vue'
import { Collapse } from 'vue-collapsed'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { useActiveRouteStore } from '@/stores/index.js'

export default {
    name: 'ActiveRoutePoint',
    components: { ButtonComponent, Collapse, ActiveRouteTask },
    props: {
        pointData: {
            type: Object,
            required: true
        },
        lastCompleted: {
            type: Boolean,
            required: true
        },
        firstUncompleted: {
            type: Boolean,
            required: true
        }
    },
    setup() {
        const activeRouteStore = useActiveRouteStore()

        return {
            activeRouteStore
        }
    },
    data() {
        return {
            collapsed: true
        }
    },
    methods: {
        togglePointCollapse() {
            this.collapsed = !this.collapsed
            const bodyElement = this.$refs.activeRoutePoint

            if (!this.collapsed && bodyElement) {
                setTimeout(() => {
                    bodyElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }, 300)
            }
        },

        completePoint() {
            this.activeRouteStore.updatePointStatus(this.pointData.id, 'Completed')
        },

        uncompletePoint() {
            this.activeRouteStore.updatePointStatus(this.pointData.id, 'Uncompleted')
        }
    }
}
</script>

<style scoped lang="scss">
.active-route-point {
    padding: 0.5rem 0;
}

.route-point-header {
    display: flex;
    align-items: center;
    cursor: pointer;

    .route-status {
        margin-right: 0.5rem;
        width: 0.5rem;
        min-width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
    }

    .route-address {
        margin-right: auto;
    }

    .route-collapse {
        margin-left: 1rem;
        height: 1.875rem;
    }

    .collapse-icon {
        transition: all ease-in-out 0.3s;

        &.opened {
            transform: rotate(-180deg);

            circle {
                fill: $yellow;
            }

            path {
                stroke: $white;
            }
        }
    }
}

.route-point-body {
    padding: 0.7rem 0 0;
    overflow: hidden;

    .button-component {
        font-size: 0.875rem;
        min-height: 2.5rem;
        padding: 0.4rem 0.5rem;
        margin-top: 1rem;
    }
}
</style>
