<template>
    <vue-tel-input
        ref="phoneInput"
        v-model="computedInputValue"
        :class="{ 'not-valid': !isPhoneValid }"
        :only-countries="contriesList"
        :disabled="disabled"
        mode="international"
        :valid-characters-only="true"
        :preferred-countries="['UA', 'DE', 'PL']"
        :input-options="{ showDialCode: true, maxlength: 18, type: 'tel' }"
        @blur="validatePhoneNumber"
    ></vue-tel-input>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'
import { parsePhone } from '@/helpers/parse-phone.js'

export default {
    name: 'PhoneInputComponent',
    components: { VueTelInput },
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            contriesList: [
                'UA',
                'DE',
                'PT',
                'PL',
                'FR',
                'RO',
                'ES',
                'GB',
                'LT',
                'AT',
                'IT',
                'SE',
                'CZ',
                'HR',
                'LV',
                'EE',
                'BG',
                'NO',
                'LU',
                'CH',
                'SK',
                'NL',
                'FI',
                'HU',
                'BE',
                'MD',
                'DK',
                'RS',
                'CY',
                'SI',
                'MK',
                'IE',
                'LI'
            ],
            isPhoneValid: true
        }
    },
    computed: {
        computedInputValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    mounted() {
        this.isPhoneValid = true
        this.$refs.phoneInput.focus()
    },
    methods: {
        validatePhoneNumber() {
            this.isPhoneValid = parsePhone(this.computedInputValue)

            if (!this.isPhoneValid) {
                this.$toast.error('Не вірний формат номеру телефону')
            }

            this.$parent.$emit('phoneValidation', this.isPhoneValid)
        }
    }
}
</script>

<style lang="scss">
.vue-tel-input {
    height: 3rem;
    background: $grey-light;
    border-radius: 0.6rem;
    border: 1px solid $grey-light;

    &.not-valid {
        border-color: $red;
    }

    &:focus-within {
        box-shadow: none;
        border-color: $yellow;
        background: $white;
    }

    .vti__dropdown {
        border-radius: 0.6rem;

        &-list {
            width: 20rem;

            &.below {
                top: 45px;
            }
        }

        &-item {
            padding: 0.3rem 0.5rem;

            * {
                font-size: 0.75rem;
            }

            strong {
                font-weight: 700;
            }
        }
    }

    .vti__input {
        font-size: 1rem;
        background: $grey-light;
        border-radius: 0.6rem;

        &:focus {
            background: $white;
        }
    }
}
</style>
